import { get, post, put, Delete } from "../axios";

// 会员分类-列表
export const memberClassifyList = (params) =>
  get("/web/member/classify/list", params);

// 会员分类-新增
export const memberClassifyAdd = (params) =>
  post("/web/member/classify/add", params);

// 会员分类-编辑
export const memberClassifyUpdate = (params) =>
  put("/web/member/classify/update", params);

// 会员分类-删除
export const memberClassifyDelete = (id) =>
  Delete("/web/member/classify/delete/" + id, "");
